import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { VideoModal } from '@zola/zola-ui/src/components/Modal/VideoModal';
import { isDirty } from 'redux-form';

import { hideModal, hideSecondaryModal, showModal } from 'actions/ModalActions';

// Utils function
import { asyncComponent } from '../../util/asyncComponent';
import featureFlags from '../../util/featureFlags';

// settings modals
const AcknowledgeUpdateModal = asyncComponent(() =>
  import('components/common/modals/AcknowledgeUpdateModal')
);
const DeleteOtherRegistriesModal = asyncComponent(() =>
  import('pages/settings/otherRegistries/components/DeleteRegistryModal')
);
const EditOtherRegistriesModal = asyncComponent(() =>
  import('pages/settings/otherRegistries/components/EditRegistryModal')
);
const WeddingDateModal = asyncComponent(() => import('components/common/modals/WeddingDateModal'));

const AuthModal = asyncComponent(() => import('./auth/AuthModal/')); // eslint-disable-line
const WeddingPartyMemberForm = asyncComponent(() =>
  import('../manage/website/entities/WeddingPartyMemberForm')
);
const FaqForm = asyncComponent(() => import('../manage/website/entities/FaqForm'));
const PoiForm = asyncComponent(() => import('../manage/website/entities/PoiForm'));
const TravelForm = asyncComponent(() => import('../manage/website/entities/Travel/TravelForm'));
const HomepageSectionForm = asyncComponent(() =>
  import('../manage/website/entities/Homepage/components/HomepageSectionForm')
);
const RegistryForm = asyncComponent(() =>
  import('../manage/website/entities/RegistryContainer/Registry/RegistryForm')
);
const CropModal = asyncComponent(() => import('../manage/website/entities/CropModal'));
const PhotoForm = asyncComponent(() => import('../manage/website/entities/PhotoForm'));

const ChecklistEditSettingsV2 = asyncComponent(() =>
  import('../manage/ChecklistV2/ChecklistEditSettings')
);
const GuestMessageModal = asyncComponent(() => import('../manage/guestlist/GuestMessageModal'));
const DeleteGuestGroupModal = asyncComponent(() =>
  import('../manage/guestlist/DeleteGuestGroupModal')
);
const RegistryAndWebsiteShareModal = asyncComponent(() =>
  import('./modals/RegistryAndWebsiteShareModal/RegistryAndWebsiteShareModal')
);
const DeleteChecklistModalV2 = asyncComponent(() =>
  import('../manage/ChecklistV2/ChecklistDeleteTaskModal')
);
const RemoveOrRevokePartnerModal = asyncComponent(() =>
  import('../manage/ChecklistV2/ChecklistRemoveOrRevokeModal')
);
const DeletePhotoModal = asyncComponent(() => import('../manage/website/DeletePhotoModal'));
const RegistryItemModal = asyncComponent(() => import('../registryitem/RegistryItem'));
const CartModal = asyncComponent(() => import('../cart/CartModal'));
const PublishWebsiteModalv2 = asyncComponent(() =>
  import('../manage/website/PublishWebsiteModalv2')
);
const PublishRegistryModalv3 = asyncComponent(() =>
  import('../manage/website/PublishRegistryModalv3')
);
const PublishPageErrorModal = asyncComponent(() =>
  import('../manage/website/PublishPageErrorModal')
);
const DidntBuyModal = asyncComponent(() => import('../registryitem/steps/DidntBuyModal'));
const PublishRsvpModal = asyncComponent(() => import('../manage/website/PublishRsvpModal'));
const SetShippingAddressModal = asyncComponent(() =>
  import('../manage/common/SetShippingAddressModal')
);
const RequestMissingInfoModalV2 = asyncComponent(() =>
  import('./modals/RequestMissingInfoModal/RequestMissingInfoModal')
);
const ReorderPagesModal = asyncComponent(() => import('../manage/website/ReorderPagesModal'));
const HidePagesModal = asyncComponent(() => import('../manage/common/HidePagesModal/index'));
const EventFormModalV2 = asyncComponent(() => import('../manage/common/EventFormModalV2'));
const EditVirtualEventModal = asyncComponent(() =>
  import('../manage/common/EditVirtualEventModal')
);
const AddGuestGroupModal = asyncComponent(() =>
  import('./modals/AddGuestGroupModal/AddGuestGroupModal')
);
const EditGuestGroupModal = asyncComponent(() =>
  import('./modals/EditGuestGroupModal/EditGuestGroupModal')
); // 👈 Invites (less stuff)
const EditGuestGroupModalWedding = asyncComponent(() =>
  import('./modals/EditGuestGroupWeddingsModal/EditGuestGroupWeddingsModal')
); // 👈 weddings (more tabs and fields)
const CheckGuestDuplicatesModal = asyncComponent(() =>
  import('../manage/guestlist/CheckGuestDuplicatesModal')
);
const MultipleEventsModal = asyncComponent(() =>
  import('./modals/MultipleEventsModal/MultipleEventsModal')
);
const BulkUninviteConfirmationModal = asyncComponent(() =>
  import('./modals/BulkUninviteConfirmationModal/BulkUninviteConfirmationModal')
);
const UninviteGuestConfirmationModal = asyncComponent(() =>
  import('./modals/UninviteGuestConfirmationModal/UninviteGuestConfirmationModal')
);
const ConfirmActionModal = asyncComponent(() =>
  import('./modals/ConfirmActionModal/ConfirmActionModal')
);
const CustomizationWarningModal = asyncComponent(() =>
  import('components/manage/website/WebsiteCustomization/components/CustomizationWarningModal')
);
const DeclineRsvpRequestModal = asyncComponent(() =>
  import('./modals/DeclineRsvpRequestModal/DeclineRsvpRequestModal')
);
const RsvpSuccessModal = asyncComponent(() =>
  import('../PublicWebsite/pages/rsvp/RsvpSuccessModal/RsvpSuccessModal')
);

// seating chart:
const AddChartModal = asyncComponent(() => import('../manage/seatingchart/Modals/AddChart'));
const NewTableModal = asyncComponent(() => import('../manage/seatingchart/Modals/NewTable'));
const EditTableModal = asyncComponent(() => import('../manage/seatingchart/Modals/EditTable'));
const DeleteTableModal = asyncComponent(() => import('../manage/seatingchart/Modals/DeleteTable'));
const DeleteChartModal = asyncComponent(() => import('../manage/seatingchart/Modals/DeleteChart'));
const SeatingTipsModal = asyncComponent(() => import('../manage/seatingchart/Modals/SeatingTips'));

const TipsModal = asyncComponent(() => import('./modals/TipsModal/TipsModal'));
const ConfirmExitModal = asyncComponent(() => import('./modals/ConfirmExitModal/ConfirmExitModal'));
const AlertModal = asyncComponent(() => import('./modals/AlertModal/AlertModal'));
const StartGuestListModal = asyncComponent(() =>
  import('./modals/StartGuestListModal/StartGuestListModal')
);
// custom banner modals
const CovidInfoModal = asyncComponent(() =>
  import('../manage/website/ManagePagesRedesign/CustomBannerSettings/components/CovidInfoModal')
);

// invitations modals
const CancelOrderModal = asyncComponent(() =>
  import('../../cards/components/common/modals/CancelOrderModal/CancelOrderModal')
);

// guest manager modals
const LeavePromptModal = asyncComponent(() =>
  import('../../pages/Events/LeavePromptModal/LeavePromptModal')
);
const SuggestionModal = asyncComponent(() =>
  import('../../pages/Events/SuggestionModal/SuggestionModal')
);

// walkthrough tour init modals
const VirtualEventOnboardingModal = asyncComponent(() =>
  import('./modals/VirtualEventOnboardingModal')
);
const PlanningDashboardTourModal = asyncComponent(() =>
  import('./modals/PlanningDashboardTourModal')
);
const DraftAndOrderWalkthroughModal = asyncComponent(() =>
  import('./modals/DraftAndOrderWalkthroughModal')
);

const DeleteEventModal = asyncComponent(() => import('components/common/modals/DeleteEventModal'));
const ConfigureAddressCollectionWelcomeModal = asyncComponent(() =>
  import('components/manage/guestlist/addressCollection/ConfigureAddressCollectionWelcomeModal')
);

const DeleteRSVPEventModal = asyncComponent(() =>
  import('./modals/DeleteRSVPEventModal/DeleteRSVPEventModal')
);

const DstdUnavailableModal = asyncComponent(() =>
  import('./modals/DstdUnavailableModal/DstdUnavailableModal')
);

const PhoneVerificationModal = asyncComponent(() =>
  import('../../pages/settings/PasswordSecurity/components/PhoneVerificationModal')
);

const PhoneVerificationConfirmEmailModal = asyncComponent(() =>
  import('../../pages/settings/PasswordSecurity/components/ConfirmEmailModal')
);

const confirmExitModalFlag = featureFlags.get('confirmExitModalFlag');

const MODAL_COMPONENTS = {
  AUTH: AuthModal,
  PHOTO: PhotoForm,
  WEDDING_PARTY: WeddingPartyMemberForm,
  FAQ: FaqForm,
  POI: PoiForm,
  TRAVEL: TravelForm,
  HOMEPAGE_SECTION: HomepageSectionForm,
  REGISTRY: RegistryForm,
  DELETE_GUEST_GROUP: DeleteGuestGroupModal,
  GUEST_MESSAGE: GuestMessageModal,
  CHECKLIST_EDIT_V2: ChecklistEditSettingsV2,
  DELETE_CHECKLIST_TASK_V2: DeleteChecklistModalV2,
  REMOVE_OR_REVOKE_PARTNER: RemoveOrRevokePartnerModal,
  DELETE_PHOTO: DeletePhotoModal,
  CROP_MODAL: CropModal,
  VIDEO: VideoModal,
  REGISTRY_ITEM: RegistryItemModal,
  // CART_MODAL: this should probably be replaced/deleted now that 'publicWebNavCartModal' is live.
  CART_MODAL: CartModal,
  WEDDING_DATE: WeddingDateModal,
  PUBLISH_WEBSITE: PublishWebsiteModalv2,
  PUBLISH_REGISTRY: PublishRegistryModalv3,
  PUBLISH_PAGE_ERROR: PublishPageErrorModal,
  DIDNT_BUY_MODAL: DidntBuyModal,
  PUBLISH_RSVP: PublishRsvpModal,
  SET_SHIPPING_ADDRESS: SetShippingAddressModal,
  REQUEST_MISSING_INFO_V2: RequestMissingInfoModalV2,
  REORDER_PAGES: ReorderPagesModal,
  HIDE_PAGES: HidePagesModal,
  EVENT_FORM: EventFormModalV2,
  VIRTUAL_EVENT_FORM: EditVirtualEventModal,
  DELETE_EVENT: DeleteEventModal,
  ADD_GUEST_GROUP: AddGuestGroupModal,
  EDIT_GUEST_GROUP_WEDDING: EditGuestGroupModalWedding,
  CHECK_GUEST_DUPLICATES: CheckGuestDuplicatesModal,
  MULTIPLE_EVENTS: MultipleEventsModal,
  BULK_UNINVITE_CONFIRMATION: BulkUninviteConfirmationModal,
  UNINVITE_GUEST_CONFIRMATION: UninviteGuestConfirmationModal,
  CONFIRM_ACTION: ConfirmActionModal,
  ACKNOWLEDGE_UPDATE_MODAL: AcknowledgeUpdateModal,
  RSVP_SUCCESS_MODAL: RsvpSuccessModal,
  /* other modals */
  DECLINE_RSVP_REQUEST: DeclineRsvpRequestModal,
  /* invitations modals */
  CANCEL_ORDER: CancelOrderModal,
  EDIT_GUEST_GROUP: EditGuestGroupModal,
  /* seating chart */
  ADD_CHART_MODAL: AddChartModal,
  NEW_TABLE_MODAL: NewTableModal,
  EDIT_TABLE_MODAL: EditTableModal,
  DELETE_TABLE_MODAL: DeleteTableModal,
  DELETE_CHART_MODAL: DeleteChartModal,
  SEATING_TIPS_MODAL: SeatingTipsModal,
  DELETE_OTHER_REGISTRIES_MODAL: DeleteOtherRegistriesModal,
  EDIT_OTHER_REGISTRIES_MODAL: EditOtherRegistriesModal,
  TIPS_MODAL: TipsModal,
  ALERT_MODAL: AlertModal,
  START_GUEST_LIST_MODAL: StartGuestListModal,
  REGISTRY_WEBSITE_SHARE_MODAL: RegistryAndWebsiteShareModal,
  /* custom banner modals */
  COVID_INFO_MODAL: CovidInfoModal,
  /* guest manager modals */
  GUEST_MANAGER_LEAVE_PROMPT_MODAL: LeavePromptModal,
  GUEST_MANAGER_SUGGESTION_MODAL: SuggestionModal,
  /* tour modals */
  VIRTUAL_EVENT_ONBOARDING_MODAL: VirtualEventOnboardingModal,
  PLANNING_DASH_TOUR_MODAL: PlanningDashboardTourModal,
  SUPER_LINK_WELCOME_MODAL: ConfigureAddressCollectionWelcomeModal,
  DELETE_RSVP_EVENT: DeleteRSVPEventModal,
  DSTD_UNAVAILABLE_MODAL: DstdUnavailableModal,
  DRAFT_AND_ORDER_WALKTHROUGH_MODAL: DraftAndOrderWalkthroughModal,
  /* website customization modals */
  WEBSITE_CUSTOMIZATION_SAVE: CustomizationWarningModal,
  /* Account Settings Modals */
  PHONE_VERIFICATION_MODAL: PhoneVerificationModal,
  PHONE_VERIFICATION_CONFIRM_EMAIL_MODAL: PhoneVerificationConfirmEmailModal,
};

let currentModalForm;
class ModalRoot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmExitModalHidden: true,
    };

    this.onBackgroundClick = this.onBackgroundClick.bind(this);
    this.onSecondaryBackgroundClick = this.onSecondaryBackgroundClick.bind(this);
    this.onEscape = this.onEscape.bind(this);
    this.hideConfirmExitModal = this.hideConfirmExitModal.bind(this);
    this.showConfirmExitModal = this.showConfirmExitModal.bind(this);
  }

  onBackgroundClick(e) {
    const { disableClose, hideModalFn, isCurrentModalFormDirty, modalProps } = this.props;
    if (confirmExitModalFlag) {
      if (!disableClose && e.target === e.currentTarget) {
        if (!isCurrentModalFormDirty) {
          if (modalProps && modalProps.onHideModal) {
            modalProps.onHideModal();
          }
          hideModalFn();
        } else {
          this.showConfirmExitModal();
        }
      }
    } else if (!disableClose && e.target === e.currentTarget) {
      if (modalProps && modalProps.onHideModal) {
        modalProps.onHideModal();
      }
      hideModalFn();
    }
  }

  onEscape({ keyCode }) {
    const { modalProps } = this.props;
    if (!modalProps.disableClose && keyCode === 27) {
      if (modalProps && modalProps.onHideModal) {
        modalProps.onHideModal();
      }
      this.props.hideModalFn();
    }
  }

  onHide() {
    if (typeof document !== 'undefined') {
      if (document.body) {
        document.body.className = document.body.className.replace(/ ?modal-open/g, '');
      }
      document.removeEventListener('keydown', this.onEscape);
    }
  }

  onSecondaryBackgroundClick(e) {
    const { hideSecondaryModalFn } = this.props;
    if (e.target === e.currentTarget) {
      hideSecondaryModalFn();
    }
  }

  onShow() {
    if (typeof document !== 'undefined') {
      if (document.body) {
        const orig = document.body.className;
        document.body.className = `${orig} modal-open`.trim();
      }
      document.addEventListener('keydown', this.onEscape);
    }
  }

  hideConfirmExitModal() {
    this.setState({
      isConfirmExitModalHidden: true,
    });
  }

  shouldLaunchWebNavModal() {
    // do not launch web-wedding modal if we are trying to sign up and web-nav is available
    const { modalType, modalProps } = this.props;
    return (
      modalType === MODAL_COMPONENTS.AUTH &&
      modalProps.authState === 'signup' &&
      window.zolaNav &&
      window.zolaNav.openSignupModal
    );
  }

  showConfirmExitModal() {
    this.setState({
      isConfirmExitModalHidden: false,
    });
  }

  render() {
    const {
      modalType,
      modalProps,
      hideModalFn,
      hideSecondaryModalFn,
      modalOptions,
      secondaryModal,
      showModalFn,
      isCurrentModalFormDirty,
    } = this.props;
    if (!modalType) {
      this.onHide();
      return null;
    }
    if (!this.shouldLaunchWebNavModal()) {
      this.onShow();
    }
    const SpecificModal = MODAL_COMPONENTS[modalType];
    const SpecificSecondaryModal = MODAL_COMPONENTS[secondaryModal.modalType];
    currentModalForm = modalProps ? modalProps.formIdentifier : undefined;

    return (
      <div className={modalOptions.className}>
        <div
          tabIndex="-1"
          role="dialog"
          className="modal in"
          style={{ zIndex: 1050, display: 'block' }}
          onClick={this.onBackgroundClick}
        >
          <div
            id={modalOptions && modalOptions.modalId ? modalOptions.modalId : null}
            className={`modal-dialog modal-${
              modalOptions && modalOptions.size ? modalOptions.size : 'lg'
            }`}
          >
            <div className="modal-content" style={{ ...modalOptions.styleOverride }}>
              {!modalProps.hideClose && (
                <button
                  type="button"
                  className="modal-close"
                  onClick={e => {
                    hideModalFn(e);
                    if (
                      modalProps &&
                      modalProps.onHideModal &&
                      typeof modalProps.onHideModal === 'function'
                    ) {
                      modalProps.onHideModal();
                    }
                    this.hideConfirmExitModal();
                  }}
                >
                  ×
                </button>
              )}
              <SpecificModal {...modalProps} hideModalFn={hideModalFn} showModalFn={showModalFn} />
            </div>
            {isCurrentModalFormDirty &&
              !this.state.isConfirmExitModalHidden &&
              confirmExitModalFlag && (
                <ConfirmExitModal
                  hideModalFn={hideModalFn}
                  hideConfirmExitModal={this.hideConfirmExitModal}
                />
              )}
          </div>
        </div>
        <div className="modal-backdrop in" />
        {SpecificSecondaryModal && (
          <div>
            <div
              tabIndex="-1"
              role="dialog"
              className="modal in"
              style={{ zIndex: 1060, display: 'block' }}
              onClick={this.onSecondaryBackgroundClick}
            >
              <div
                className={`modal-dialog modal-${
                  secondaryModal.modalOptions && secondaryModal.modalOptions.size
                    ? secondaryModal.modalOptions.size
                    : 'sm'
                }`}
                style={{ margin: '60px auto' }}
              >
                <div className="modal-content">
                  {!secondaryModal.modalProps.hideClose && (
                    <button type="button" className="modal-close" onClick={hideSecondaryModalFn}>
                      ×
                    </button>
                  )}
                  <SpecificSecondaryModal
                    {...secondaryModal.modalProps}
                    hideModalFn={hideSecondaryModalFn}
                    showModalFn={showModalFn}
                  />
                </div>
              </div>
            </div>
            <div className="modal-backdrop in" style={{ zIndex: '1055' }} />
          </div>
        )}
      </div>
    );
  }
}

ModalRoot.propTypes = {
  modalType: PropTypes.string,
  modalProps: PropTypes.shape({
    callback: PropTypes.func,
  }),
  hideModalFn: PropTypes.func.isRequired,
  modalOptions: PropTypes.shape({
    size: PropTypes.string,
    formIdentifier: PropTypes.string,
    modalId: PropTypes.string,
  }),
  secondaryModal: PropTypes.shape({}),
  hideSecondaryModalFn: PropTypes.func,
  showModalFn: PropTypes.func.isRequired,
  styleOverride: PropTypes.shape({}),
};

const mapStateToProps = state => ({
  ...state.modal,
  isCurrentModalFormDirty: currentModalForm ? isDirty(currentModalForm)(state) : null,
});

const mapDispatchToProps = {
  hideModalFn: hideModal,
  hideSecondaryModalFn: hideSecondaryModal,
  showModalFn: showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalRoot);
